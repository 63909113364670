import React from 'react'

const NotFound = () => {
    return (
        <div>
            Page Not Found
        </div>
    )
}

export default NotFound
